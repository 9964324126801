import { useState } from 'react'

import { ContentContainer } from '../../../components/page-layout'
import { PersonalDataEdit } from '../../../components/profile/PersonalDataEdit'
import { useUser } from '../../../user/hooks/useUser'

export const EmployeePersonalDataScreen = () => {
  // -- State --
  const [editable, setEditable] = useState<boolean>(false)

  // -- Hooks --
  const { user, updateUser, updateLoading } = useUser()

  // -- Render --
  return (
    <ContentContainer>
      <PersonalDataEdit
        user={user}
        updateUser={updateUser}
        disabled={updateLoading}
        editable={editable}
        setEditable={setEditable}
      />
    </ContentContainer>
  )
}
