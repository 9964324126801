import { styled } from 'styled-components'

import { ButtonPrimary } from './ButtonPrimary'
import { ButtonSecondary } from './ButtonSecondary'

export const IconButtonPrimary = styled(ButtonPrimary)`
  padding: 0;
  width: 40px;
  min-width: 40px;
  height: 40px;

  display: flex;
  justify-content: center;
  align-items: center;
`

export const IconButtonSecondary = styled(ButtonSecondary)`
  padding: 0;
  width: 40px;
  min-width: 40px;
  height: 40px;

  display: flex;
  justify-content: center;
  align-items: center;
`
