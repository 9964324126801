import { Trans, useTranslation } from 'react-i18next'
import { styled } from 'styled-components'

import { BodySmallRegular } from '../../../components/typography'
import { applyVat, formatDecimals } from '../../../core/utils/number'

import type { OptimileEvsesChargingPoint } from 'types'

type ChargerGroupCardProps = {
  group: OptimileEvsesChargingPoint[]
}

export const ChargerGroupCard = ({ group }: ChargerGroupCardProps) => {
  //-- Hooks --
  const { t } = useTranslation()

  // -- Vars --
  const available = group.filter(
    (charger) => charger.status === 'available'
  ).length

  const vatPercentage = group[0].connector.tariff.vat
  const startingPrice = formatDecimals(
    applyVat(group[0].connector.tariff.starting_price, vatPercentage)
  )

  const startingPriceText = startingPrice ? `€${startingPrice} + ` : ''

  const energyPrices = group[0].connector.tariff.energy_components
  const energyPriceText = (() => {
    // If price is 0 return an empty string
    if (energyPrices[0].price === 0) return ''

    const initialPrice = formatDecimals(
      applyVat(energyPrices[0].price, vatPercentage),
      3
    )

    const initialText = t('map.detail.group.tarrif-kwh', {
      currency: '€',
      price: initialPrice,
    })

    // If no limit return initial price
    if (!energyPrices[0].limit) return initialText

    const additionalPrices = energyPrices
      .map((price, index) => {
        if (index === 0) return

        return t('map.detail.group.tarrif-kwh-extra', {
          kwh: energyPrices[index - 1].limit,
          currency: '€',
          price: formatDecimals(applyVat(price.price, vatPercentage), 3),
        })
      })
      .filter(Boolean)

    return initialText + ' ' + additionalPrices.join(', ')
  })()

  const hourPrices = group[0].connector.tariff.time_components
  const hourPriceText = (() => {
    // If price is 0 return an empty string
    if (hourPrices[0].price === 0) return ''

    const initialPrice = formatDecimals(
      applyVat(hourPrices[0].price / 60, vatPercentage),
      3
    )
    const initialText = t('map.detail.group.tarrif-hour', {
      currency: '€',
      price: initialPrice,
    })

    // If no limit return initial price
    if (!hourPrices[0].limit) return initialText

    const additionalPrices = hourPrices
      .map((price, index) => {
        if (index === 0) return

        const pricePerMinute = formatDecimals(
          applyVat(price.price / 60, vatPercentage),
          3
        )

        return t('map.detail.group.tarrif-hour-extra', {
          time: hourPrices[index - 1].limit / 60,
          currency: '€',
          price: pricePerMinute,
        })
      })
      .filter(Boolean)

    return initialText + ' ' + additionalPrices.join(', ')
  })()

  const isFree = !startingPriceText && !energyPriceText && !hourPriceText

  const topText = startingPriceText + energyPriceText

  // -- Render --
  return (
    <StCard>
      <StTopText>
        <Trans
          t={t}
          i18nKey={'map.detail.availability'}
          values={{
            available: available,
            total: group.length,
            kw: Math.round(group[0].connector.power || 0),
          }}
          components={{
            b: available > 0 ? <Green /> : <Red />,
          }}
        />
      </StTopText>
      {isFree ? (
        <BodySmallRegular>{t('map.detail.group.free')}</BodySmallRegular>
      ) : (
        <>
          <BodySmallRegular>{topText}</BodySmallRegular>
          <StLightText>{hourPriceText}</StLightText>
        </>
      )}
    </StCard>
  )
}

const StCard = styled.div`
  background-color: ${({ theme }) => theme.theme.colors['nonary-9']};
  padding: ${({ theme }) => theme.UI.SpacingPx.Space4};
  border-radius: ${({ theme }) => theme.UI.SpacingPx.Space1};
`

const Red = styled.strong`
  color: ${({ theme }) => theme.theme.colors.error};
`

const Green = styled.strong`
  color: ${({ theme }) => theme.theme.colors['quaternary-1']};
`

const StTopText = styled(BodySmallRegular)`
  margin-bottom: ${({ theme }) => theme.UI.SpacingPx.Space2};
`

const StLightText = styled(BodySmallRegular)`
  color: ${({ theme }) => theme.theme.text.body['gray-mid']};
`
