import { useState } from 'react'
import { toast } from 'react-hot-toast'
import { Trans, useTranslation } from 'react-i18next'
import { styled } from 'styled-components'

import { ButtonPrimary } from '../../../components/button/ButtonPrimary'
import { Dialog } from '../../../components/general/Dialog'
import { Loader } from '../../../components/general/Loader'
import { QuoteCard } from '../../../components/quote/QuoteCard'
import {
  BodyLargeSemiBold,
  BodyMediumRegular,
  H5,
} from '../../../components/typography'
import { useQuote } from '../../../quotes/hooks/useQuote'
import { useUser } from '../../../user/hooks/useUser'

import type { UserWithContractAndCards } from 'types'

type DetailQuoteApprovalProps = {
  employee: UserWithContractAndCards
}

export const DetailQuoteApproval = ({ employee }: DetailQuoteApprovalProps) => {
  // -- State --
  const [dialogOpen, setDialogOpen] = useState(false)

  // -- Hooks --
  const { t } = useTranslation()
  const { refetch } = useUser()

  // -- Data --
  const { quote, loading, error, approveQuote, approveLoading } = useQuote(
    employee.members[0].id
  )

  // -- Handlers --
  const handleApproveQuote = async () => {
    setDialogOpen(false)
    await approveQuote()
    refetch()
  }

  if (loading) {
    return <Loader />
  }

  if (error || !quote) {
    toast.error(t('admin.employee-detail.quote.error'))
    return null
  }

  const approvedByEmployer =
    employee.hcpStatus.status === 'QuotationApprovedByEmployer'
  const approvedByBothParties =
    employee.hcpStatus.status === 'QuotationApproved'

  if (approvedByBothParties || approvedByEmployer) {
    return (
      <StGrid>
        <div>
          <H5>
            <Trans
              t={t}
              i18nKey={
                approvedByBothParties
                  ? 'admin.employee-detail.quote.approved.title'
                  : 'admin.employee-detail.quote.employer-approved.title'
              }
              values={{
                name: employee.firstName,
              }}
              components={{
                br: <br />,
              }}
            />
          </H5>
          <BodyMediumRegular>
            {t(
              approvedByBothParties
                ? 'admin.employee-detail.quote.approved.description'
                : 'admin.employee-detail.quote.employer-approved.description',
              { name: employee.firstName }
            )}
          </BodyMediumRegular>
        </div>
        <QuoteCard
          fullName={employee.fullName || ''}
          date={new Date(quote.Created_Time)}
          quote={quote}
          forUserId={employee.id}
        />
      </StGrid>
    )
  }

  // -- Render --
  return (
    <>
      <StContainer>
        <QuoteCard
          fullName={employee.fullName || ''}
          date={new Date(quote.Created_Time)}
          withBorder={false}
          quote={quote}
          forUserId={employee.id}
        />

        <StCard>
          <BodyLargeSemiBold>
            {t('admin.employee-detail.quote.approve.title')}
          </BodyLargeSemiBold>
          <ButtonPrimary
            compact
            onClick={() => setDialogOpen(true)}
            loading={approveLoading}
            size="md"
          >
            {t('admin.employee-detail.quote.approve.button')}
          </ButtonPrimary>
        </StCard>
      </StContainer>
      <Dialog
        title={t('admin.employee-detail.quote.approve-dialog.title')}
        description={t(
          'admin.employee-detail.quote.approve-dialog.description'
        )}
        onClickCloseButton={() => setDialogOpen(false)}
        open={dialogOpen}
        onClickPrimaryButton={handleApproveQuote}
        primaryButtonText={t('admin.employee-detail.quote.approve.button')}
      />
    </>
  )
}

const StContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.theme.colors['nonary-7']};
  border-radius: ${({ theme }) => theme.UI.SpacingPx.Space2};
  padding: ${({ theme }) => theme.UI.SpacingPx.Space10};

  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space6};
`

const StGrid = styled.div`
  border: 1px solid ${({ theme }) => theme.theme.colors['nonary-7']};
  border-radius: ${({ theme }) => theme.UI.SpacingPx.Space2};
  padding: ${({ theme }) => theme.UI.SpacingPx.Space10};

  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  align-items: center;

  h5 {
    margin: 0;
    margin-bottom: ${({ theme }) => theme.UI.SpacingPx.Space3};
  }
`

const StCard = styled.div`
  border: 1px solid ${({ theme }) => theme.theme.colors['nonary-7']};
  border-radius: ${({ theme }) => theme.UI.SpacingPx.Space2};
  padding: ${({ theme }) => theme.UI.SpacingPx.Space7};

  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space3};
`
