import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { format } from 'date-fns'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { styled } from 'styled-components'
import { useMediaQuery } from 'usehooks-ts'

import { breakpoints } from '../../../../theme/layout/breakpoints'
import { ButtonTertiary } from '../../../components/button/ButtonTertiary'
import {
  BodySmallRegular,
  BodySmallRegularCss,
  BodySmallSemiBold,
  H5,
} from '../../../components/typography'
import { useGetDateFnsLocale } from '../../../core/hooks/useGetDateFnsLocale'
import { formatDecimals } from '../../../core/utils/number'
import { useMember } from '../../../member/hooks/useMember'
import { valueToDate } from '../../../translations/utils/date'
import { useUser } from '../../../user/hooks/useUser'

import { ReimbursementStatusChip } from './ReimbursementStatusChip'

import type { ChargingSessionWithMspAndReimbursements } from 'types'

type ChargingSessionInfoHcpProps = {
  chargingSession: ChargingSessionWithMspAndReimbursements
}

export const ChargingSessionInfoHcp = ({
  chargingSession,
}: ChargingSessionInfoHcpProps) => {
  const dateFnsLocale = useGetDateFnsLocale()
  const { t } = useTranslation()
  const { currentMember } = useMember()
  const { user } = useUser()
  const navigate = useNavigate()
  const isDesktop = useMediaQuery(breakpoints.desktop)

  const startDate = valueToDate(chargingSession.start)
  const startHour = format(startDate, 'HH:mm', dateFnsLocale)
  const endDate = valueToDate(chargingSession.end)
  const endHour = format(endDate, 'HH:mm', dateFnsLocale)

  const fullStartDate = format(startDate, 'dd MMMM yyyy', dateFnsLocale)

  const price = formatDecimals(chargingSession.hcpPrice)
  const kwh = formatDecimals(chargingSession.kwh)

  const userName =
    chargingSession.mspCard?.memberId === currentMember.id
      ? `${user.firstName} ${user.lastName}`
      : t('employee.chargingSessions.guest')

  const handleRefundsClick = useCallback(() => {
    navigate('/refunds', {
      state: {
        backTo: location.pathname,
      },
    })
  }, [navigate])

  return (
    <>
      {isDesktop ? (
        <StChargingSessionTitle>{fullStartDate}</StChargingSessionTitle>
      ) : null}
      <StChargingSessionInfo>
        <StChargingSessionRow>
          <BodySmallSemiBold>{userName}</BodySmallSemiBold>
        </StChargingSessionRow>
        <StChargingSessionRow>
          <StData>
            {startHour} - {endHour}
          </StData>
          <StData>€ {price}</StData>
        </StChargingSessionRow>
        <StChargingSessionRow>
          <StData>
            <FontAwesomeIcon icon={['fass', 'bolt']} />{' '}
            {t('employee.chargingSessions.detail.total-kwh', {
              amount: kwh,
            })}
          </StData>
          <ReimbursementStatusChip
            chargingSession={chargingSession}
            member={currentMember}
          />
        </StChargingSessionRow>
      </StChargingSessionInfo>
      <StChargingSessionAction>
        <StToRefundsButton
          icon={['fasr', 'arrow-right']}
          onClick={handleRefundsClick}
        >
          {t('employee.chargingSessions.detail.refunds-overview')}
        </StToRefundsButton>
      </StChargingSessionAction>
    </>
  )
}

const StChargingSessionTitle = styled(H5)`
  margin-top: 0;
  margin-bottom: ${({ theme }) => theme.UI.SpacingPx.Space8};
`

const StChargingSessionInfo = styled.div`
  ${BodySmallRegularCss}
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space3};

  padding-top: ${({ theme }) => theme.UI.SpacingPx.Space4};

  media ${breakpoints.desktop} {
    padding-top: 0;
  }
`

const StChargingSessionRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const StChargingSessionAction = styled.div`
  ${BodySmallRegularCss}
  margin-top: ${({ theme }) => theme.UI.SpacingPx.Space8};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space2};
`

const StToRefundsButton = styled(ButtonTertiary)`
  justify-content: space-between;
`

const StData = styled(BodySmallRegular)`
  color: ${({ theme }) => theme.theme.text.body['gray-mid']};
`
