import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// eslint-disable-next-line import/named
import { isAxiosError } from 'axios'
import { deepEqual } from 'fast-equals'
import { Form, Formik } from 'formik'
import { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Map as MapBox, Marker } from 'react-map-gl'
import { useNavigate } from 'react-router-dom'
import { css, styled } from 'styled-components'
import * as Yup from 'yup'
import 'yup-phone-lite'

import { EmployeeRoutes } from '../../../../../routing/routes'
import { breakpoints } from '../../../../../theme/layout/breakpoints'
import { useAuthMemberAxios } from '../../../../api/hooks/useAuthMemberAxios'
import { Urls } from '../../../../api/urls'
import { Checkbox } from '../../../../components/checkbox/Checkbox'
import { Input, StError } from '../../../../components/form/Input'
import { BodyMediumSemiBoldCss } from '../../../../components/typography'
import { onHover } from '../../../../core/lib/styleHelpers'
import { useGeoLocation } from '../../../../location/hooks/useGeoLocation'
import { useUser } from '../../../../user/hooks/useUser'
import { StCurrentLocation } from '../../../map/components/Map'
import { MapActions } from '../../../map/components/MapActions'
import { FloatingContainerButton } from '../../components/FloatingContainerButton'
import { OnboardingStepTitle } from '../../components/OnboardingStepTitle'
import { PersonalAddressSearch } from '../../components/PersonalAddressSearch'
import { useOnboarding } from '../../hooks/useOnboarding'
import { formatAddress } from '../../util/address'

import type {
  AddressData,
  PartialAddressData,
} from '../../../../components/form/address-search/types'
import type { PartialMapboxAddress } from '../../components/PersonalAddressSearch'
import type { FormikHelpers } from 'formik'
import type { MapRef } from 'react-map-gl'

export const PersonalDataStep = () => {
  // -- Hooks --
  const { t } = useTranslation()
  const { refetch } = useUser()
  const {
    handleNext,
    setLoading,
    values: onboardingValues,
    simplifiedFlow,
  } = useOnboarding()
  const navigate = useNavigate()
  const { watchLocation, location, locationPermissionGranted } =
    useGeoLocation()
  const { user } = useUser()

  // -- State --
  const [mapRef, setMapRef] = useState<MapRef | null>(null)
  const [address, setAddress] = useState<PartialAddressData | AddressData>(
    onboardingValues.personalData.address
  )
  const [showLocationPicker, setShowLocationPicker] = useState(false)
  const [viewState, setViewState] = useState({
    longitude: 4.3517,
    latitude: 50.8503,
    zoom: 7,
  })

  // -- Data --
  const [, execute] = useAuthMemberAxios(
    {
      url: Urls.PersonalData,
      method: 'POST',
    },
    {
      manual: true,
    }
  )

  //  -- Vars --
  const addressString = formatAddress(address)

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required(t('onboarding.account.firstName.error')),
    lastName: Yup.string().required(t('onboarding.account.lastName.error')),
    addressSearch: Yup.string().required(t('onboarding.account.address.error')),
    tel: Yup.string()
      .phone(['BE'], t('onboarding.account.tel.format-error'))
      .required(t('onboarding.account.tel.error')),
    terms: Yup.boolean().oneOf([true]),
  })

  // -- Effects --
  useEffect(() => {
    watchLocation()
  }, [])

  // -- Handlers --
  const handleSubmit = async (
    values: typeof onboardingValues.personalData,
    {
      setFieldError,
    }: Pick<
      FormikHelpers<
        typeof onboardingValues.personalData & {
          email: string
          addressSearch: string
        }
      >,
      'setFieldError'
    >,
    coordinates?: {
      longitude?: number
      latitude?: number
    }
  ) => {
    // User also has to have entered a valid address
    if (!address) return

    if (
      (!address.latitude || !address.longitude) &&
      (!coordinates?.latitude || !coordinates?.longitude)
    ) {
      setShowLocationPicker(true)
      return
    }

    setLoading(true)

    const newValues = {
      ...values,
      address: {
        ...address,
        longitude: address.longitude || coordinates?.longitude,
        latitude: address.latitude || coordinates?.latitude,
      } as AddressData,
    }

    if (!deepEqual(newValues, onboardingValues.personalData)) {
      try {
        await execute({
          data: newValues,
        })

        await refetch()
      } catch (error) {
        if (isAxiosError(error)) {
          const errorObject = error.response?.data

          // Try to map expected server error key to formik key
          if (Array.isArray(errorObject?.message)) {
            errorObject.message.forEach((message: unknown) => {
              if (typeof message === 'string' && message.startsWith('tel')) {
                setFieldError('tel', t('onboarding.account.tel.error.invalid'))
              }
            })
          }

          setLoading(false)
          return console.error('Failed to save personal data')
        }
      }
    }

    if (user.hcpStatus.status === 'Inapplicable') {
      navigate(EmployeeRoutes.Root, { replace: true })
    } else {
      handleNext({
        ...onboardingValues,
        personalData: newValues,
      })
    }
  }

  const handleSelectAddress = (mapboxAddress: PartialMapboxAddress | null) => {
    setAddress({
      street:
        mapboxAddress?.features?.[0].properties.context.address.street_name ||
        '',
      number:
        mapboxAddress?.features?.[0].properties.context.address
          .address_number || '',
      city: mapboxAddress?.features?.[0].properties.context.place?.name || '',
      postcode:
        mapboxAddress?.features?.[0].properties.context.postcode?.name || '',
      country:
        mapboxAddress?.features?.[0].properties.context.country.country_code ||
        '',
      countryName:
        mapboxAddress?.features?.[0].properties.context.country.name || '',
      longitude: mapboxAddress?.features?.[0]?.geometry?.coordinates?.[0],
      latitude: mapboxAddress?.features?.[0]?.geometry?.coordinates?.[1],
    })
  }

  return (
    <Formik
      // Add 2 extra fields to the formik state
      // We can use them in the touched and errors object
      initialValues={{
        ...onboardingValues.personalData,
        addressSearch: addressString ?? '',
        // Check if the user still has the placeholder lastName for zoho
        lastName:
          onboardingValues.personalData.lastName === user.workEmail
            ? ''
            : onboardingValues.personalData.lastName,
        email: '',
        terms: user.acceptedTerms,
      }}
      validationSchema={validationSchema}
      validateOnMount
      // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
      onSubmit={({ addressSearch, email, ...values }, formikHelpers) =>
        handleSubmit(values, formikHelpers)
      }
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        setFieldValue,
        isSubmitting,
        setFieldTouched,
        setFieldError,
      }) =>
        showLocationPicker ? (
          <StMapContainer>
            <OnboardingStepTitle>
              {t('onboarding.account.location-selection.title')}
            </OnboardingStepTitle>
            <StMapWrapper>
              <StMap>
                <MapBox
                  {...viewState}
                  reuseMaps
                  dragRotate={false}
                  touchPitch={false}
                  minZoom={3}
                  pitchWithRotate={false}
                  ref={(ref) => setMapRef(ref)}
                  onMove={(event) => setViewState(event.viewState)}
                  mapboxAccessToken={import.meta.env.VITE_MAPBOX_ACCESS_TOKEN}
                  style={{
                    width: '100dvw',
                    height: '100%',
                  }}
                  mapStyle="mapbox://styles/bothrsdev/cloy3aalb013d01qo1geifax9"
                  onRender={(event) => {
                    event.target.resize()
                  }}
                  scrollZoom={{ around: 'center' }}
                >
                  {locationPermissionGranted && (
                    <Marker
                      latitude={location.latitude}
                      longitude={location.longitude}
                    >
                      <StCurrentLocation />
                    </Marker>
                  )}
                </MapBox>

                <StMarkerWrapper>
                  <StMarker $selected={true} $clusterSize={4}>
                    <FontAwesomeIcon icon={['fass', 'house']} fontSize={18} />
                  </StMarker>
                </StMarkerWrapper>

                <MapActions map={mapRef} />
              </StMap>
            </StMapWrapper>
            <FloatingContainerButton
              disabled={isSubmitting}
              onClick={() => {
                const coordinates = mapRef?.getMap().getCenter()

                handleSubmit(
                  values,
                  { setFieldError },
                  {
                    longitude: coordinates?.lng,
                    latitude: coordinates?.lat,
                  }
                )
              }}
              title={t('onboarding.account.location-selection.submit')}
            />
          </StMapContainer>
        ) : (
          <StContainer>
            <OnboardingStepTitle>
              {t('onboarding.account.title')}
            </OnboardingStepTitle>

            <Form>
              <StFormContainer>
                <Input
                  type="text"
                  name="firstName"
                  value={values.firstName}
                  error={errors.firstName}
                  touched={touched.firstName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={isSubmitting}
                  label={t('onboarding.account.firstName')}
                />
                <Input
                  type="text"
                  name="lastName"
                  value={values.lastName}
                  error={errors.lastName}
                  touched={touched.lastName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={isSubmitting}
                  label={t('onboarding.account.lastName')}
                />
                <PersonalAddressSearch
                  name="addressSearch"
                  displayValue={addressString}
                  label={
                    simplifiedFlow
                      ? t('onboarding.account.MSP.address')
                      : t('onboarding.account.HCP.address')
                  }
                  types={['address']}
                  onSelect={(address) => {
                    setFieldValue(
                      'addressSearch',
                      address?.features?.[0].properties.context.address
                        .street_name
                    )

                    handleSelectAddress(address)

                    setTimeout(() => {
                      // Mark field as touched in next render cycle -> this fixes a bug on mobile
                      setFieldTouched('addressSearch')
                    }, 50)
                  }}
                  onBlur={() => {
                    setFieldTouched('addressSearch')
                  }}
                  disabled={isSubmitting}
                  error={errors.addressSearch}
                  touched={touched.addressSearch}
                />
                <Input
                  type="tel"
                  name="tel"
                  value={values.tel}
                  error={errors.tel}
                  touched={touched.tel}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={isSubmitting}
                  label={t('onboarding.account.tel')}
                />
              </StFormContainer>

              {/* This is extra error handling to catch an Optimile error that only occurs on this step */}
              {errors.email && <StError>{errors.email}</StError>}

              <div>
                <Checkbox
                  name="terms"
                  onChange={(value) => setFieldValue('terms', value)}
                  checked={values.terms}
                  label={
                    <Trans
                      i18nKey="onboarding.account.terms"
                      components={{
                        link1: (
                          <StInlineLink
                            href={t('profile.privacy.item1.link')}
                            target="_blank"
                          />
                        ),
                        link2: (
                          <StInlineLink
                            href={t('profile.privacy.item2.link')}
                            target="_blank"
                          />
                        ),
                      }}
                    />
                  }
                />
              </div>

              <FloatingContainerButton
                title={t('onboarding.account.submit')}
                disabled={
                  !values.firstName ||
                  !values.lastName ||
                  !values.tel ||
                  !address.street ||
                  !values.terms
                }
              />
            </Form>
          </StContainer>
        )
      }
    </Formik>
  )
}

const StContainer = styled.div`
  padding-bottom: ${({ theme }) =>
    `calc(var(--inset-bottom, ${theme.UI.SpacingPx.Space6}) + var(--sticky-button-container-height))`};
`

const StMapContainer = styled.div`
  margin-bottom: calc(
    var(--inset-bottom, 0) + var(--sticky-button-container-height)
  );

  height: calc(100vh - var(--navigation-height) - 128px);

  display: flex;
  flex-direction: column;
  align-items: stretch;
`

const StFormContainer = styled.div`
  display: grid;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space6};

  margin: ${({ theme }) => theme.UI.SpacingPx.Space8} 0;

  @media ${breakpoints.desktop} {
    grid-template-columns: 1fr 1fr;
    gap: ${({ theme }) => theme.UI.SpacingPx.Space10};

    margin: ${({ theme }) => theme.UI.SpacingPx.Space15} 0;
    margin-bottom: ${({ theme }) => theme.UI.SpacingPx.Space10};
  }
`

// Location selector
const StMapWrapper = styled.div`
  flex: 1;

  position: relative;
`

const StMap = styled.div`
  flex: 1;

  height: 100%;

  position: absolute;
  left: 50%;
  transform: translateX(-50%);
`

const StMarkerWrapper = styled.div`
  position: absolute;
  bottom: 50%;
  left: 50%;

  // Make sure the pointer is in the middle of the map so zooming doesn't feel off
  transform: translateX(-50%) translateY(-2%);

  pointer-events: none;
`

const StMarker = styled.div<{
  $selected?: boolean
  $clusterSize?: number
  $available?: boolean
}>`
  height: calc(40px + ${({ $clusterSize = 0 }) => $clusterSize}px);
  aspect-ratio: 1 / 1;

  display: flex;
  justify-content: center;
  align-items: center;

  ${BodyMediumSemiBoldCss}

  background-color: ${({ theme, $selected }) =>
    $selected ? theme.theme.colors.black : theme.theme.colors['primary-1']};
  color: ${({ theme, $selected }) =>
    $selected ? theme.theme.colors['primary-1'] : theme.theme.colors.black};

  border-radius: 999px;

  cursor: pointer;

  margin-bottom: 7px;

  ${({ $clusterSize, $available }) =>
    $clusterSize
      ? ''
      : css`
          &::before {
            content: '';

            aspect-ratio: 1 /1;

            position: absolute;
            top: -2px;
            right: -2px;

            width: 12px;
            border-radius: 999px;
            background-color: ${({ theme }) =>
              $available
                ? theme.theme.colors['quaternary-1']
                : theme.theme.colors.error};

            box-shadow: 0px 0px 0px 3px #bdc7cc;
          }
        `}

  &::after {
    content: '';

    aspect-ratio: 1 /1;

    position: absolute;
    bottom: 0px;
    z-index: -1;

    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 15px solid
      ${({ theme, $selected }) =>
        $selected ? theme.theme.colors.black : theme.theme.colors['primary-1']};
  }
`

const StInlineLink = styled.a`
  background: none;
  outline: none;
  border: none;
  padding: 0;

  cursor: pointer;
  text-decoration: underline;
  color: ${({ theme }) => theme.theme.colors.black};

  ${onHover(css`
    opacity: 0.6;
  `)}
`
