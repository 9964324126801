import { useEffect, useMemo, useState } from 'react'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { styled } from 'styled-components'

import { replaceParametersInPath } from '../../../../routing/lib/replaceIdInPath'
import { EmployerRoutes } from '../../../../routing/routes'
import { EventType, useTracking } from '../../../analytics/hooks/useTracking'
import { useAuthMemberAxios } from '../../../api/hooks/useAuthMemberAxios'
import { Urls } from '../../../api/urls'
import { ButtonSecondary } from '../../../components/button/ButtonSecondary'
import { ButtonTertiary } from '../../../components/button/ButtonTertiary'
import { Select } from '../../../components/form/Select'
import { Dialog } from '../../../components/general/Dialog'
import {
  BodyLargeMedium,
  BodyMediumRegular,
  H5,
} from '../../../components/typography'

import type { SelectOption } from '../../../components/form/Select'
import type { HcpContract, MspContract } from 'database'

type EmployeeContractCardProps = {
  hcpContract?: HcpContract
  mspContract?: boolean
  companyId: string
  employeeMemberId: string
  refetchEmployee: () => void
}

export const EmployeeContractCard = ({
  hcpContract,
  mspContract,
  companyId,
  employeeMemberId,
  refetchEmployee,
}: EmployeeContractCardProps) => {
  // -- State --
  const [isHcpDialogOpen, setIsHcpDialogOpen] = useState(false)
  const [isMspDialogOpen, setIsMspDialogOpen] = useState(false)
  const [selectedHcpContract, setSelectedHcpContract] = useState<string>()
  const [selectedMspContract, setSelectedMspContract] = useState<string>()
  const [hasExistingDevice, setHasExistingDevice] = useState<string>('false')

  // -- Hooks --
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { trackEvent } = useTracking()

  // -- Data --
  const [{ data: contracts, loading: loadingContracts, error }] =
    useAuthMemberAxios<{
      hcpContracts: HcpContract[]
      mspContracts: MspContract[]
    }>({
      url: Urls.contractData,
      params: {
        companyId,
      },
    })

  const [, inviteMember] = useAuthMemberAxios(
    {
      url: replaceParametersInPath(Urls.employeeHcpInvite, {
        memberId: employeeMemberId,
      }),
      method: 'POST',
    },
    {
      manual: true,
    }
  )

  const [, requestMspCard] = useAuthMemberAxios(
    {
      url: replaceParametersInPath(Urls.employeeRequestMsp, {
        memberId: employeeMemberId,
      }),
      method: 'POST',
    },
    {
      manual: true,
    }
  )

  // -- Effects --
  useEffect(() => {
    if (contracts) {
      setSelectedHcpContract(contracts.hcpContracts[0]?.id)
      setSelectedMspContract(contracts.mspContracts[0]?.id)
    }
  }, [contracts])

  // -- Handlers --
  const handleClick = () => {
    navigate(EmployerRoutes.Contracts)
    trackEvent(EventType.Click, 'go_to_contracts')
  }

  const handleHcpSubmit = async () => {
    const { status } = await inviteMember({
      data: {
        hcpContractId: selectedHcpContract,
        hasExistingDevice: hasExistingDevice === 'true',
      },
    })

    setIsHcpDialogOpen(false)

    if (status === 200 || status === 201) {
      toast.success(t('admin.employee-detail.contracts.dialog.success'))
      refetchEmployee()
    } else {
      toast.error(t('admin.employee-detail.contracts.dialog.success'))
    }

    trackEvent(EventType.Submit, 'enroll_member_to_HCP')
  }

  const handleMspSubmit = async () => {
    const { status } = await requestMspCard({
      data: {
        mspContractId: selectedMspContract,
      },
    })

    setIsMspDialogOpen(false)

    if (status === 200 || status === 201) {
      toast.success(t('admin.employee-detail.contracts.dialog.msp.success'))
      refetchEmployee()
    } else {
      toast.error(t('admin.employee-detail.contracts.dialog.msp.success'))
    }

    trackEvent(EventType.Submit, 'request_msp_card')
  }

  // -- Vars --
  const hcpContractOptions = useMemo(() => {
    return (
      contracts?.hcpContracts?.map((contract) => ({
        key: contract.id,
        label: contract.displayName ?? contract.name,
      })) ?? []
    )
  }, [contracts?.hcpContracts])

  const mspContractOptions = useMemo(() => {
    return (
      contracts?.mspContracts?.map((contract) => ({
        key: contract.id,
        label: contract.displayName ?? contract.name,
      })) ?? []
    )
  }, [contracts?.mspContracts])

  const hasExistingDeviceOptions: SelectOption[] = [
    {
      key: 'false',
      label: t('admin.employee-detail.contracts.dialog.existing-device.no'),
    },
    {
      key: 'true',
      label: t('admin.employee-detail.contracts.dialog.existing-device.yes'),
    },
  ]

  // -- Render --
  if (error) {
    throw new Error('Failed to load HCP contracts')
  }

  return (
    <>
      <StCard>
        <StHeaderContainer>
          <H5>{t('admin.employee-detail.contracts.title')}</H5>
          <StFlex>
            <ButtonTertiary
              compact
              icon={['fasr', 'arrow-up-right-from-square']}
              iconAlignment="left"
              onClick={handleClick}
              size="sm"
            >
              {t('admin.employee-detail.contracts.button')}
            </ButtonTertiary>

            {!hcpContract && (
              <ButtonSecondary
                compact
                icon={['fass', 'add']}
                iconAlignment="left"
                onClick={() => setIsHcpDialogOpen(true)}
                size="sm"
              >
                {t('admin.employee-detail.contracts.add-button')}
              </ButtonSecondary>
            )}
            {!mspContract && (
              <ButtonSecondary
                compact
                icon={['fass', 'add']}
                iconAlignment="left"
                onClick={() => setIsMspDialogOpen(true)}
                size="sm"
              >
                {t('admin.employee-detail.contracts.add-msp-button')}
              </ButtonSecondary>
            )}
          </StFlex>
        </StHeaderContainer>
        <StContentContainer>
          <div>
            <StContractTitle>
              {t('admin.employee-detail.contracts.charge-pole')}
            </StContractTitle>
            <BodyLargeMedium>
              {hcpContract ? hcpContract.name : '-'}
            </BodyLargeMedium>
          </div>

          <div>
            <StContractTitle>
              {t('admin.employee-detail.contracts.card')}
            </StContractTitle>

            <BodyLargeMedium>
              {mspContract ? t('admin.employee-detail.contracts.default') : '-'}
            </BodyLargeMedium>
          </div>
        </StContentContainer>
      </StCard>
      <Dialog
        open={isHcpDialogOpen}
        onOpenChange={setIsHcpDialogOpen}
        title={t('admin.employee-detail.contracts.dialog.title')}
        description={t('admin.employee-detail.contracts.dialog.description')}
        primaryButtonText={t(
          'admin.employee-detail.contracts.dialog.button-primary'
        )}
        secondaryButtonText={t(
          'admin.employee-detail.contracts.dialog.button-secondary'
        )}
        onClickCloseButton={() => setIsHcpDialogOpen(false)}
        onClickSecondaryButton={() => setIsHcpDialogOpen(false)}
        onClickPrimaryButton={handleHcpSubmit}
      >
        <StContractsForm>
          <Select
            label={t(
              'admin.employee-detail.contracts.dialog.existing-device-label'
            )}
            options={hasExistingDeviceOptions}
            value={hasExistingDevice}
            onChange={(value) => {
              setHasExistingDevice(value ?? 'false')
            }}
          />
          <Select
            disabled={loadingContracts}
            options={hcpContractOptions}
            value={selectedHcpContract}
            onChange={(value) => setSelectedHcpContract(value)}
            label={t(
              'admin.employee-detail.contracts.dialog.hcp-contract-label'
            )}
          />
        </StContractsForm>
      </Dialog>

      <Dialog
        open={isMspDialogOpen}
        onOpenChange={setIsMspDialogOpen}
        title={t('admin.employee-detail.contracts.dialog.msp.title')}
        description={t(
          'admin.employee-detail.contracts.dialog.msp.description'
        )}
        primaryButtonText={t(
          'admin.employee-detail.contracts.dialog.msp.button-primary'
        )}
        secondaryButtonText={t(
          'admin.employee-detail.contracts.dialog.msp.button-secondary'
        )}
        onClickCloseButton={() => setIsMspDialogOpen(false)}
        onClickSecondaryButton={() => setIsMspDialogOpen(false)}
        onClickPrimaryButton={handleMspSubmit}
      >
        <StContractSelection>
          <Select
            disabled={loadingContracts}
            options={mspContractOptions}
            value={selectedMspContract}
            onChange={(value) => setSelectedMspContract(value)}
            label={t('admin.employee-detail.contracts.dialog.msp-label')}
            icon={['fass', 'credit-card-front']}
          />
        </StContractSelection>
      </Dialog>
    </>
  )
}

const StCard = styled.div`
  border: 1px solid ${({ theme }) => theme.theme.colors['nonary-7']};
  border-radius: ${({ theme }) => theme.UI.SpacingPx.Space2};

  padding: ${({ theme }) => theme.UI.SpacingPx.Space10};
`

const StHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;

  h5 {
    margin: 0;
  }
`

const StContentContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;

  margin-top: ${({ theme }) => theme.UI.SpacingPx.Space8};
`

const StContractTitle = styled(BodyMediumRegular)`
  color: ${({ theme }) => theme.theme.text.body['gray-mid']};
  margin-bottom: ${({ theme }) => theme.UI.SpacingPx.Space3};
`

const StFlex = styled.div`
  display: flex;
  align-items: end;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space3};
`

const StContractSelection = styled.div`
  margin-top: ${({ theme }) => theme.UI.SpacingPx.Space5};
`

const StContractsForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space6};

  margin-top: ${({ theme }) => theme.UI.SpacingPx.Space6};
`
