import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { format } from 'date-fns'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'
import { useMediaQuery } from 'usehooks-ts'

import { minutesToHoursAndMinutes } from '../../../../../../../packages/utils/src/time/minutesToHoursAndMinutes'
import { breakpoints } from '../../../../theme/layout/breakpoints'
import { BodySmallRegularCss, H5 } from '../../../components/typography'
import { useGetDateFnsLocale } from '../../../core/hooks/useGetDateFnsLocale'
import { applyVat, formatDecimals } from '../../../core/utils/number'
import { valueToDate } from '../../../translations/utils/date'

import type { ChargingSession } from 'database'

type ChargingSessionInfoMspProps = {
  chargingSession: ChargingSession
}

export const ChargingSessionInfoMsp = ({
  chargingSession,
}: ChargingSessionInfoMspProps) => {
  const dateFnsLocale = useGetDateFnsLocale()
  const { t } = useTranslation()
  const isDesktop = useMediaQuery(breakpoints.desktop)

  const fullDate = useMemo(() => {
    const startDate = valueToDate(chargingSession.start)
    return format(startDate, 'dd MMMM yyyy', dateFnsLocale)
  }, [chargingSession.start, dateFnsLocale])

  const startHour = useMemo(() => {
    const startDate = valueToDate(chargingSession.start)
    return format(startDate, 'HH:mm', dateFnsLocale)
  }, [chargingSession.start, dateFnsLocale])

  const endHour = useMemo(() => {
    const endDate = valueToDate(chargingSession.end)
    return format(endDate, 'HH:mm', dateFnsLocale)
  }, [chargingSession.end, dateFnsLocale])

  const duration = useMemo(() => {
    return minutesToHoursAndMinutes(chargingSession.duration)
  }, [chargingSession.duration])

  const totalCost = useMemo(() => {
    return formatDecimals(
      applyVat(chargingSession.mspPrice, chargingSession.vat)
    )
  }, [chargingSession.mspPrice, chargingSession.vat])

  return (
    <>
      {isDesktop ? (
        <StChargingSessionTitle>{fullDate}</StChargingSessionTitle>
      ) : null}
      <StChargingSessionInfo>
        <StChargingSessionRow>
          <p>
            <FontAwesomeIcon icon={['fasl', 'bolt']} />{' '}
            {duration.hours > 0 &&
              `${t('employee.chargingSessions.detail.duration-hours', {
                hours: duration.hours,
              })} `}
            {t('employee.chargingSessions.detail.duration-minutes', {
              minutes: duration.minutes,
            })}
          </p>
          <p>
            {startHour} - {endHour}
          </p>
        </StChargingSessionRow>
        <StChargingSessionRow>
          <p>
            {t('employee.chargingSessions.detail.total-kwh', {
              amount: chargingSession.kwh.toFixed(2),
            })}
          </p>
          <p>€{totalCost}</p>
        </StChargingSessionRow>
      </StChargingSessionInfo>
      <StChargingSessionLocation>
        <p>{chargingSession.address}</p>
        <p>
          {chargingSession.city}, {chargingSession.country}
        </p>
      </StChargingSessionLocation>
    </>
  )
}

const StChargingSessionTitle = styled(H5)`
  margin-top: 0;
  margin-bottom: ${({ theme }) => theme.UI.SpacingPx.Space8};
`

const StChargingSessionInfo = styled.div`
  ${BodySmallRegularCss}
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space3};

  padding-top: ${({ theme }) => theme.UI.SpacingPx.Space4};

  media ${breakpoints.desktop} {
    padding-top: 0;
  }
`

const StChargingSessionRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const StChargingSessionLocation = styled.div`
  ${BodySmallRegularCss}
  margin-top: ${({ theme }) => theme.UI.SpacingPx.Space8};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space2};
`
